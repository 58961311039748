import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Getting Started",
  "sort": 1,
  "title": "A primer on GraphQL",
  "subtitle": "Learn about GraphQL, how it works, and how to use it"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Every GraphQL API, including ours, is backed by a strongly typed GraphQL schema.
The schema clearly defines what operations are supported by the API, allowing clients to verify correctness ahead of time.`}</p>
    <h2>{`What's GraphQL?`}</h2>
    <p>{`GraphQL is a query language, created by Facebook in 2012. They have been using it internally and in production for a while, and more recently they have published a specification for it, which has received a really positive response from the developer community. Companies like GitHub, Pinterest, and Coursera, have already started adopting it and using externally and internally.`}</p>
    <p>{`A few advantages of using GraphQL are:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Hierarchical`}</strong>{` - query is shaped like the data it returns.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Client-specified queries`}</strong>{` - queries are encoded in the client rather than the server. They return exactly what the client asks for, and no unnecessary data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Strongly typed`}</strong>{` - you can validate a query syntactically and within the GraphQL type system before execution. This also helps leverage powerful tools that improve the development experience, such as code generation.`}</p>
      </li>
    </ul>
    <p>{`One big advantage when using GraphQL is having an efficient way to get resources by querying for exactly what you need.
You will never need to perform multiple API calls to stich together data.`}</p>
    <p>{`Here's an example GraphQL query to retrieve a specific person from our API:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  signatureOrder(id: "abcd") {
    status
    signatories {
      status
      href
    }
  }
}
`}</code></pre>
    <p>{`And here is an example response:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "signatureOrder": {
    "status": "OPEN",
    "signatories": [
      {
        "status": "SIGNED",
        "href": "https://example.com/..."
      },
      {
        "status": "REJECTED",
        "href": "https://example.com/..."
      },
      {
        "status": "OPEN",
        "href": "https://example.com/..."
      }
    ]
  }
}
`}</code></pre>
    <h2>{`Using the Criipto GraphQL API`}</h2>
    <p>{`GraphQL queries can be executed against `}<inlineCode parentName="p">{`https://signatures-api.criipto.com/v1/graphql`}</inlineCode>{`.`}</p>
    <p>{`Queries must be sent as a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request containing a `}<inlineCode parentName="p">{`JSON`}</inlineCode>{` body with the parameters `}<inlineCode parentName="p">{`query`}</inlineCode>{` and optionally `}<inlineCode parentName="p">{`variables`}</inlineCode>{`.`}</p>
    <p>{`You will need a set of API credentials to execute queries, please see `}<a parentName="p" {...{
        "href": "/signatures/getting-started/register-application/"
      }}>{`Registering your application`}</a></p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST -H "Content-Type: application/json" \\
  -u clientId:clientSecret \\
  --data '{"query": "{ signatureOrder(id: $id) { signatories { status } } }", "variables": {"id": "abcd"}}' \\ 
  https://signatures-api.criipto.com/v1/graphql
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      